<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
        :showBtn="true"
        :showAddNew="false"
        title="Return General Bill"
        @onClickCloseButton="goToList"
      />
    </div>

    <div class="my-2 px-2">
      <div class="row mt-2 gy-1">
        <div class="col-md-3">
          <label class="form-label">Date</label>
          <input
              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
              tabindex="1"
              type="date"
              placeholder=""
              v-model="formData.date"
          >
        </div>
        <div class="col-md-3">
          <label class="form-label">Invoice Date</label>
          <input
              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
              tabindex="1"
              type="date"
              placeholder=""
              v-model="formData.invoice_date"
              disabled=""
          >
        </div>
        <div class="col-md-3">
          <label class="form-label">Invoice No</label>
          <div class="d-flex align-items-center justify-content-md-end mb-1">
            <div class="input-group input-group-merge invoice-edit-input-group">
              <div class="input-group-text">
                <span>{{ prefix }}-</span>
              </div>
              <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                     v-model="serial"   disabled="">
            </div>
          </div>
        </div>
      </div>
      <div class="row gy-1">
        <div class="col-md-3">
          <div class="d-flex align-items-center justify-content-between filter-label" style="height: 25px">
            <label for="colFormLabel" class="col-form-label">Party: </label>
          </div>
          <AsyncSelect
              placeholder="Select Party"
              v-model="contactProfile"
              :api-service="fetchContactProfiles"
              :additional-query="{ with_mobile: 1, roles: 'customer' }"
              :format-label="formatPatientLabel"
              disabled=""
          />
        </div>
        <div class="col-md-3">
          <label class="form-label">Project</label>
          <v-select
              placeholder="Select Project"
              v-model="formData.project_id"
              :options="projects"
              label="name"
              :reduce="name => name.id"
              disabled=""
          />
        </div>
        <div class="col-md-3">
          <label class="form-label">Business Unit</label>
          <v-select
              placeholder="Select Business"
              v-model="formData.business_id"
              :options="business"
              label="name"
              disabled=""
              :reduce="name => name.id"
          />
        </div>
        <div class="col-md-3">
          <label class="form-label">Cost Centre</label>
          <v-select
              placeholder="Select Cost Centre"
              v-model="formData.cost_centre_id"
              :options="costCentres"
              label="name"
              :reduce="name => name.id"
              disabled=""
          />
        </div>
        <div class="col-md-3">
          <label class="form-label">Ship from (Warehouse)</label>
          <v-select
              placeholder="Select Warehouse Location"
              v-model="formData.location_id"
              :options="locations"
              label="text"
              :reduce="text => text.id"
          />
        </div>
        <div class="col-md-3">
          <label class="form-label">Receivable Accounts</label>
          <v-select
              placeholder="Account Head"
              v-model="formData.account_head_id"
              :options="accountPayable"
              label="name"
              :reduce="name => name.id"
              disabled=""
          />
        </div>
        <div class="col-md-3">
          <label class="form-label">Vat Accounts</label>
          <v-select
              placeholder="Vat Payable Account Head"
              v-model="formData.vat_payable_account_head_id"
              :options="vatPayable"
              label="name"
              :reduce="name => name.id"
              disabled=""
          />
        </div>
        <div class="col-md-3">
          <label class="form-label">Order No/PI No</label>
          <input
              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
              tabindex="1"
              type="text"
              placeholder="Please add order number"
              v-model="formData.order_number"
              disabled=""
          >
        </div>
        <div class="col-md-3">
          <div class="d-flex align-items-center justify-content-between filter-label" style="height: 25px">
            <label for="colFormLabel" class="col-form-label">Sales Person</label>
          </div>
          <AsyncSelect
              placeholder="Select Sales Person"
              v-model="salesPerson"
              :api-service="fetchContactProfiles"
              :additional-query="{ with_mobile: 1 }"
              :format-label="formatPatientLabel"
              disabled=""
          />
        </div>
        <div class="col-md-3">
          <label class="form-label">Commission %</label>
          <input
              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
              tabindex="1"
              type="number"
              placeholder="Please add commission %"
              v-model="formData.commission_percent"
              disabled=""
          >
        </div>
        <div class="col-md-3">
          <label class="form-label">Commission Amount</label>
          <input
              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
              tabindex="1"
              type="number"
              placeholder="Please add commission amount"
              v-model="formData.commission_amount"
              disabled=""
          >
        </div>
        <div class="col-md-3">
          <label class="form-label">LC No</label>
          <input
              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
              tabindex="1"
              type="text"
              placeholder="Please add LC number"
              v-model="formData.lc_number"
              disabled=""
          >
        </div>
      </div>
    </div>

    <div class="col-12 px-2">
      <ListTable
        :tableItems="tableItems"
        @addNewItem="addNewItem"
        :vatRate="vatRate"
        :salesAccountHeads="salesAccountHeads"
        :is-hide-add-btn="true"
        :is-disabled="true"
        :from-return="true"
      />
    </div>
    <div class="row px-2">
      <div class="col-md-6">
        <div class="mb-1">
          <label class="form-label" for="description">Ledger Memo</label>
          <vField
            as="textarea"
            name="description"
            type="number"
            class="form-control"
            v-model="formData.description"
            disabled="disabled"
          />
        </div>
      </div>
      <div class="col-md-5 ml-auto mt-auto mb-1">
        <div class="d-flex flex-wrap gap-1 gy-2">
          <button @click="handleSubmit" class="btn btn-primary">Return bill</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {computed, inject, onMounted, provide, reactive, ref, watch} from 'vue';
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/billing/bill-manager/InvoiceHospitalTable.vue';
import {useRoute, useRouter} from 'vue-router';
import {generateUUID} from '@/services/utils/global';
import handlePurchase from '@/services/modules/purchase';
import {vatRate} from '@/data/inventory.js';
import handleCompany from "@/services/modules/company";
import hospitalPdfPrinterHelper from '@/services/utils/hospitalPdfPrinterHelper';
import handleHospital from "@/services/modules/hospital";
import useDate from "@/services/utils/day";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import salesReturnHelper from "@/services/utils/pdf/salesReturnHelper";
import { editVoucherNumber } from "@/services/utils/voucherNumberGenerator";
import handleContact from '@/services/modules/contact'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleProjects from "@/services/modules/procurement/project";
import handleCBusinesses from '@/services/modules/businesses'
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleBusinessesLocations from '@/services/modules/businessesLocations'


const {generatePdf} = hospitalPdfPrinterHelper();
const {fetchProductList, fetchSalesBill, ...rest} = handlePurchase();
const {fetchCompanyInfo} = handleCompany();
const {fetchSingleNote} = handleHospital();
const {returnBillings} = handleHospitalBilling();
const { fetchAndGeneratePdf } = salesReturnHelper();
const { fetchContactProfiles } = handleContact()
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchProjects } = handleProjects()
const { fetchBusinessList } = handleCBusinesses()
const { fetchCostCentreList } = handleCostCentres()
const { fetchBusinessLocationsList } = handleBusinessesLocations()

const $route = useRoute();
const $router = useRouter();

const companyId = $route.params.companyId;
const loader = ref(true);
const salesAccountHeads = ref([]);
const totals = ref({});
const invoiceRes = ref({});
const company = ref({});
const patient = ref({});
let contactProfile = ref(null)
let projects = ref([])
let business = ref([])
let costCentres = ref([])
let locations = ref([])
let accountPayable = ref([])
let vatPayable = ref([])
let salesPerson = ref(null)

let formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  account_head_id: null,
  ipd_register_id: null,
  vat_payable_account_head_id: null,
  mop_account_head_id: null,
  receipt_reference: null,
  receipt_description: "IPD bill payment",
  payment: null,
  project_id: null,
  business_id: null,
  cost_centre_id: null,
  location_id: null,
  discount_head_id: null,
  adjustment_amount: 0,
  status: 'active',
  bill_number: '',
  date: '',
  has_item_detail: true,
  description: '',
  order_number: null,
  sales_person_id: null,
  commission_percent: null,
  lc_number: null,
  item_details: []
});

provide('formData', formData);
provide('totals', totals);
const showSuccess = inject('showSuccess');
const showError = inject('showError');
let prefix = ref('')
let serial = ref('')

const ipdRegisterId = computed(() => formData.value.ipd_register_id);

watch(ipdRegisterId, (newValue) => {
  if (!newValue) {
     return;
  }
  const companyQuery = `?company_id=${companyId}`;
  fetchSingleNote(companyQuery, newValue).then(res => {
    if (res.data) {
      patient.value = res.data;
      formData.value.contact_profile_id = res.data.patient.id
      formData.value.ipd_register_id = res.data.id
      formData.value.service_resource_id = res.data.human_resource_id
    }
  })
})

onMounted(async () => {
  const companyQuery = `?company_id=${companyId}`;
  const salesBillQuery = `${companyQuery}&return_data=1`
  const receiveAbleRes = rest.fetchAccountReceivable(companyQuery);
  const payableRes = rest.fetchAccountPayable(companyQuery);
  const vatPayableRes = rest.fetchVatPayable(companyQuery);
  const fetchCompany = fetchCompanyInfo(companyId)
  const salesAccountHeadsRes = rest.getAccountHeadBySlag("sales_accounts", companyQuery)
  const dateHelper = useDate();
  let projectQuery = companyQuery + '&with_donors=1';
  const projectRes = fetchProjects(projectQuery)
  const businessRes = fetchBusinessList(companyQuery)
  const costCenterRes = fetchCostCentreList(companyQuery)
  const businessLocationRes = fetchBusinessLocationsList(companyQuery)

  Promise.all([
    payableRes.then(res => {
      if (res.data) formData.value.account_payable_head_id = res.data[0].id;
    }),
    receiveAbleRes.then(res => {
      if(res.data) accountPayable.value = res.data
      if (res.data) formData.value.account_head_id = res.data[0].id;
    }),
    vatPayableRes.then(res => {
      if(res.data) vatPayable.value = res.data
      if (res.data) formData.value.vat_payable_account_head_id = res.data[0].id;
    }),
    fetchCompany.then(res => {
      if (res.data) company.value = res.data;
    }),
    businessRes.then(res=> {
      if(res.data) business.value = res.data
    }),
    businessLocationRes.then(res=> {
      if(res.data) locations.value = res.data
    }),
    costCenterRes.then(res=> {
      if(res.data) costCentres.value = res.data
    }),
    salesAccountHeadsRes.then(res => {
      if (res.data) salesAccountHeads.value = res.data
    }),
    projectRes.then(res=> {
      if(res.data) projects.value = res.data
    }),

    fetchSalesBill($route.params.invoiceId, salesBillQuery).then(res => {
      if (!res.data) {
        return
      }
      const {
        id,
        date,
        account_head,
        company,
        contact_profile,
        discount_ledger,
        general,
        receipt_generals,
        stocks
      } = res.data;
      formData.value = res.data;
      let voucher = editVoucherNumber(res.data.bill_number);
      prefix.value = voucher.prefix;
      serial.value = voucher.serial;
      formData.value.sale_master_id = id;
      formData.value.adjustment_amount = res.data.adjustment_amount ?? 0;
      formData.value.invoice_date = date;
      formData.value.date = dateHelper.currentDate();
      contactProfile.value = res.data.contact;

      const items =  formatGeneralItems(general)
      tableItems.push(...items)

      if (receipt_generals && receipt_generals[0]) {
        formData.value.money_receipt_no = receipt_generals[0].receipt_master.voucher_no
        formData.value.mop_account_head_id = receipt_generals[0].receipt_master.account_head_id
        formData.value.receipt_master_id = receipt_generals[0].receipt_master.id;
        formData.value.receipt_reference = receipt_generals[0].receipt_master.receipt_reference
        formData.value.total_return_amount = res.data.paid_amount
      }
      if (discount_ledger) {
        formData.value.discount_head_id = discount_ledger.account_head_id;
        formData.value.adjustment_amount = discount_ledger.debit;
      }

    })
  ])
    .then(() => {
      loader.value = false
    })
    .catch(() => {
      loader.value = false
    })
})

let tableItems = reactive([]);

const formatGeneralItems = (generalItems) => {
  return generalItems.map(td => {
    return {
      id: td.id,
      product_id: td.product_id,
      name: td.product.name,
      option_name: td.product.name,
      description: td.description,
      quantity: td.quantity,
      rate: td.rate,
      discount: td.discount,
      sale_general_id: td.id,
      vat: td.vat,
      sub_total: td.sub_total,
      discount_amount: td.discount_amount,
      discount_percent: td.discount_percent,
      vat_amount: td.vat_amount,
      total: td.total,
      isEdit: false,
      product: {name: td.product.name},
      amount: td.amount,
      total_amount: td.total_amount
    }
  });
}

const addNewItem = () => {

  tableItems.push({
    id: generateUUID(),
    product_id: null,
    name: '',
    description: '',
    quantity: 1,
    rate: 0,
    discount_percent: 0,
    vat: 15,
    sub_total: 0,
    discount_amount: 0,
    vat_amount: 0,
    total: 0,
    isEdit: false,
  });

}

const goToList = () => {
  const {redirect_route, ...routeQuery} = $route.query
  const routeName = redirect_route ? redirect_route : "bill-manager"
  $router.push({
    name: routeName,
    params: $route.params,
    query: {
      ...routeQuery
    }
  })
}

const handleSubmit = () => {
  loader.value = true

  formData.value.item_details = tableItems;
  let copyFormData = Object.assign({}, formData.value)
  copyFormData.item_details = formData.value.item_details
  copyFormData.sale_type = $route.query.sale_type ?? "general_invoice"
  copyFormData.receipt_type = "general_invoice_return"

  returnBillings(copyFormData).then(res => {
    if (!res.status) {
      return showError(res.message)
    }
    showSuccess(res.message)
    fetchAndGeneratePdf(company.value, res.data.id, 'general_invoice')
    goToList();
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loader.value = false
  })
}

</script>
<style scoped>
address {
  margin-bottom: 0 !important;
}
</style>